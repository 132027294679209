import { Box } from "@chakra-ui/react";
import React from "react";

export default function FullScreen({ children }: any) {
  return (
    <Box mx={-4} color="white" fontWeight="bold" bgGradient="linear(to-b, white, primary.50)" my={-4} minH={"100vh"}>
      {children}
    </Box>
  );
}
