import { ICaseResponseObject } from "@config/types";
import { MIN_PRICE_PERCENTAGE, PRICE_MANDATORY_CITY_CODE_LIST } from "@store/constant.store";
import { useRedStore } from "@store/store";
import { calculatePercentage, showErrorToast } from "@utils";
import { debounce } from "lodash";

// This is a hook file which is between UI and Store + Network.
/**
 *
 * Case 1: User just update the store
 * Case 2: User update and make the API Call -> API call resolve and update the store
 *
 * So this action hook can do both
 */
function calculateProgress(total: number, value: number): number {
  if (total === 0) {
    throw new Error("Total cannot be zero.");
  }

  return parseInt(((value / total) * 100)?.toString());
}

export default function usePaymentAction() {
  const _updateBulkCollectPayment = useRedStore(state => state._updateBulkCollectPayment);
  const _updateCollectPaymentData = useRedStore(state => state._updateCollectPaymentData);

  const collectPayment = useRedStore(state => state.collectPayment);

  const initiatePaymentModule = (apiData: any) => {
    const fareAgreed = apiData?.amountPending;
    const cityCode = apiData.city;
    try {
      if (fareAgreed !== null && cityCode) {
        const totalAmount = Math.floor(fareAgreed / 100);
        const minAmount = Math.floor(calculatePercentage(totalAmount, MIN_PRICE_PERCENTAGE));
        const advanceAmount = minAmount;
        const selectedMode = "total";
        const isPriceMandatory = PRICE_MANDATORY_CITY_CODE_LIST.includes(cityCode);
        const progress = 100;
        const amount = totalAmount;
        const isFullyPaid = fareAgreed == 0;

        const payload = {
          totalAmount,
          advanceAmount,
          minAmount,
          selectedMode,
          isPriceMandatory,
          progress,
          amount,
          isFullyPaid,
        };
        _updateCollectPaymentData(payload);
      }
    } catch (error) {
      console.error("Error in initiatePaymentModule", error);
    }
  };

  const __updatePaymentAmount = (payload: any) => {
    _updateBulkCollectPayment(payload);
  };

  const __handleTotalSelectMode = () => {
    const selectedMode = "total";
    const payload = {
      selectedMode,
      amount: collectPayment?.totalAmount,
      progress: 100,
    };
    _updateBulkCollectPayment(payload);
  };

  const __handleAdvanceSelectMode = (isPriceMandatory: boolean) => {
    const selectedMode = "advance";
    const finalAmount = isPriceMandatory ? Math.min(collectPayment?.advanceAmount, collectPayment?.minAmount) : Math.max(collectPayment?.advanceAmount, collectPayment?.minAmount);
    const payload = {
      selectedMode,
      amount: finalAmount,
      progress: calculateProgress(collectPayment?.totalAmount, finalAmount),
    };
    if (!payload.amount) {
      showErrorToast("Please select valid amount.");
      return;
    }
    _updateBulkCollectPayment(payload);
  };

  const __handleAdvanceAmountChange = (amount: any, isPriceMandatory: boolean) => {
    if (amount > collectPayment?.totalAmount || amount < 0 || isNaN(amount)) return;

    const progress = !isPriceMandatory
      ? amount < collectPayment?.minAmount
        ? MIN_PRICE_PERCENTAGE
        : calculateProgress(collectPayment?.totalAmount, amount)
      : calculateProgress(collectPayment?.totalAmount, amount);

    const payload: any = {
      advanceAmount: amount,
      amount: +amount,
      progress: progress,
    };

    _updateBulkCollectPayment(payload);
    // _updateCollectPaymentKey("advanceAmount", amount);
    // _updateCollectPaymentKey("amount", amount);
  };

  return { initiatePaymentModule, __updatePaymentAmount, __handleTotalSelectMode, __handleAdvanceSelectMode, __handleAdvanceAmountChange };
}
