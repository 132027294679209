import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Stack,
  Text,
  useColorModeValue,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  FormLabel,
  FormControl,
  InputGroup,
  InputLeftAddon,
  Input,
  Divider,
  IconButton,
} from "@chakra-ui/react";
import ErrorRender from "@components/atom/ErrorRender";
import FullScreen from "@components/atom/FullScreen";
import NetworkWrapper from "@components/wrapper/NetworkWrapper";
import { pageView, paymentClickOnSendPaymentLink } from "@config/event";
import { useTitle } from "@hooks/action/useUtils";
import { useOnlinePayment, useSms } from "@hooks/api/usePayment";
import { Icon } from "@iconify/react";
import { convertPrice, convertSecondsToTime, convertTo, convertToCurrency, getValueFromURLParams } from "@utils";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useCountdown } from "usehooks-ts";

export default function CollectOnline() {
  useTitle("Collect Online");
  const { id } = useParams();
  const amount = getValueFromURLParams("amount");
  const mobile = getValueFromURLParams("mobile");
  const isSaathiFleet = getValueFromURLParams("isSaathiFleet");

  const { getQr, qrData, qrStatus } = useOnlinePayment({});

  useEffect(() => {
    if (id && amount) {
      pageView("Collect Online", {
        orderId: id,
        amount: parseInt(amount),
        isSaathiFleet: !!isSaathiFleet,
      });
      getQr(id, parseInt(amount) || 0, !!isSaathiFleet);
    }
  }, []);

  const buttonColorValue = useColorModeValue("#151f21", "gray.900");
  const navigate = useNavigate();

  return (
    <NetworkWrapper status={qrStatus}>
      <FullScreen>
        <Stack gap={4}>
          <Stack bg={"purple.50"} borderRadius={"md"} mt={4} borderWidth={1} borderColor={"gray.200"} mx={2} shadow={"sm"}>
            <Flex justify={"space-between"} p={4} align={"center"}>
              <Box>
                <Text fontSize={"2xs"}>Order ID</Text>
                <Text fontSize={"sm"} fontWeight={"semibold"}>
                  {id}
                </Text>
              </Box>
              <Box>
                <Text fontSize={"2xs"}>Amount</Text>
                <Flex borderRadius={"full"}>
                  <Text fontSize="sm" fontWeight={"semibold"}>
                    {amount ? convertToCurrency(convertPrice(parseInt(amount))) : 0}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </Stack>

          {qrData.isBookClosed ? (
            <Center h={"60vh"} as={Stack} p={4}>
              <Icon icon={"ep:success-filled"} color="green" fontSize={50} />
              <Text fontSize={"sm"} fontWeight={"semibold"} color={"gray.500"}>
                Book is already closed
              </Text>
              <Button
                px={8}
                mx={10}
                mt={10}
                bg={buttonColorValue}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
                onClick={() => navigate(`/case-history/${id}/case-overview`)}
                fontSize={"sm"}
                fontWeight={"semibold"}
              >
                Go To Case History
              </Button>
            </Center>
          ) : (
            <Center h={"60vh"} as={Stack} p={4}>
              {/* <Box>
                <Text fontSize={"sm"} fontWeight={"semibold"} color={"gray.500"}>
                  Please scan the QR code to collect the payment
                </Text>
              </Box> */}
              {qrData.qrExpiryTimeInSecs && !!amount && <SendPaymentLink qrData={qrData} orderId={id} amount={amount} mobile={mobile} />}
            </Center>
          )}
        </Stack>
      </FullScreen>
      {/* onClose={() => !collectCashAsyncState.isLoading && onClose()} */}
    </NetworkWrapper>
  );
}

interface IQrCode {
  qrImageLink: string;
  qrExpiryTimeInSecs: number;
}

const QrCode = ({ qrImageLink, qrExpiryTimeInSecs }: IQrCode) => {
  const [count, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
    countStart: qrExpiryTimeInSecs,
    intervalMs: 1000,
  });

  useEffect(() => {
    startCountdown();
    return () => resetCountdown();
  }, []);

  return (
    <Box>
      {qrImageLink?.includes("rzp.io") ? (
        <Box height={"190px"} width={"200px"} overflow={"hidden"}>
          <Image alt="Qr Code" bg="gray.200" height={"800px"} width={"600px"} objectFit={"cover"} mt={"-320px"} src={qrImageLink} />
        </Box>
      ) : (
        <Box height={"190px"} width={"250px"} overflow={"hidden"}>
          <img src={qrImageLink} style={{ marginTop: -10 }} />
        </Box>
      )}
      <Flex mb={16} mt={5} align={"center"} gap={2} justify={"center"}>
        <Text fontSize={"xs"} color={"gray.500"} textAlign={"center"}>
          Qr code Expires in:
        </Text>
        <Text fontSize={"sm"} fontWeight={"semibold"} color={"black"}>
          {/* {convertToCurrency(amount)} */}
          {/* {convertSecondsToTime(count)} */}
          {("0" + Math.floor(count / 60)).slice(-2)}:{("0" + Math.floor(count % 60)).slice(-2)}
        </Text>
      </Flex>
    </Box>
  );
};

const SendPaymentLink = ({ qrData, amount, mobile }: any) => {
  const { id: orderId } = useParams();
  const isSaathiFleet = getValueFromURLParams("isSaathiFleet");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [phoneNo, setPhoneNumber] = useState(mobile);
  const buttonColorValue = useColorModeValue("#151f21", "gray.900");
  const { sendSms, smsStatus } = useSms({ amount, orderId: orderId || "", isSaathiFleet: !!isSaathiFleet });

  const handleSendPaymentLink = () => {
    orderId &&
      paymentClickOnSendPaymentLink({
        orderId,
        amount: convertPrice(amount),
        mobile: phoneNo,
      });
    sendSms(phoneNo, () => {
      onClose();
    });
  };

  const handleNumberInput = (e: any) => {
    if (isNaN(e)) {
      return;
    }
    setPhoneNumber(e);
  };

  return (
    <>
      <>
        <QrCode qrExpiryTimeInSecs={qrData?.qrExpiryTimeInSecs} qrImageLink={qrData?.qrImageLink} />
        <Button
          px={8}
          mx={10}
          bg={buttonColorValue}
          color={"white"}
          rounded={"md"}
          _hover={{
            transform: "translateY(-2px)",
            boxShadow: "lg",
          }}
          onClick={() => onOpen()}
          fontSize={"sm"}
          fontWeight={"semibold"}
        >
          Send Payment link
        </Button>
      </>
      <Flex>
        <Text fontWeight={"normal"} fontSize={"xs"} mt={2}>
          Customer Paid? <span style={{ fontWeight: 900 }}> Check Payment Status In Live Status</span>
        </Text>
        <Center borderWidth={1} borderRadius={"md"} px={2} py={2} ml={2}>
          <Icon icon={"icon-park-twotone:broadcast"} color="black" />
        </Center>
      </Flex>
      <Drawer isOpen={isOpen} placement="bottom" onClose={() => !smsStatus?.isLoading && onClose()}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Send Payment Link</DrawerHeader>
          <Divider />
          <DrawerBody p={10}>
            <Center>
              <Stack spacing={4} justify={"space-between"} align={"center"} minH={"25vh"}>
                <Center flexDir={"column"} textAlign={"center"}>
                  <FormControl my={5} isRequired>
                    <FormLabel fontSize={"xs"} mb={0.75}>
                      Payment link message will be sent on the following number;
                    </FormLabel>
                    <InputGroup size={"sm"}>
                      <InputLeftAddon children="+91" />
                      <Input defaultValue={mobile || ""} onChange={e => handleNumberInput(e.target.value)} bg={"white"} type="tel" placeholder="phone number" maxLength={10} />
                    </InputGroup>
                  </FormControl>
                </Center>
                <Button
                  px={8}
                  bg={buttonColorValue}
                  color={"white"}
                  rounded={"md"}
                  _hover={{
                    transform: "translateY(-2px)",
                    boxShadow: "lg",
                  }}
                  onClick={handleSendPaymentLink}
                  w={"full"}
                  isLoading={smsStatus.isLoading}
                  isDisabled={phoneNo?.length !== 10}
                >
                  Send Link
                </Button>
              </Stack>
            </Center>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
